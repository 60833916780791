@import "../../variables.scss";

.projectsPage {
    box-sizing: border-box;
    margin: 0;
    padding: 5vw;
    width: 100%;
    background-color: $tertiary-color;

    h2 {
        color: $primary-color;
    }

    .profile {
        border-bottom: 1px solid $secondary-color;
    }

    .experience {
        border-top: 1px solid $secondary-color;

        .stack {
            display: flex;
            flex-direction: column;
        }
    }

    .projectsBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        div {
            margin-bottom: $base-spacing * 5;
        }
    }
}

@media only screen and (min-width: 426px) {

}

@media only screen and (min-width: 768px) {
    .projectsPage {
        .projectsBox {
            flex-direction: row;
            justify-content: space-evenly;
            flex-wrap: wrap;

            div {
                width: 25vw;
                margin-bottom: $base-spacing * 5;
            }
        }

        .experience {
            .stack {
                flex-direction: row;
                justify-content: space-between;

                .right {
                    p {
                    text-align: right;
                    }
                }
            }
        }
    }
}