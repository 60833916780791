@import "../../variables.scss";

.cardBack {
    width: 100%;
    background-color: $tertiary-color;

    h3 {
        color: $primary-color;
        margin-bottom: $base-spacing * 2;
    }

    p {
        color: $secondary-color;
        margin-bottom: $base-spacing * 2;
        text-align: justify;
    }

    .techBar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        p {
            margin: 0;
        }

        .techStack {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            p {
                margin: 0 0 0 3px;
                display: inline;
            }
        }
    }
}