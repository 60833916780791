@import "../../variables.scss";

.profileBar {
    width: 100%;
    box-sizing: border-box;
    background-color: $primary-color;

    h2 {
        color: $secondary-color;
        text-align: center;
        margin: 0;
        padding-bottom: $base-spacing * 6;

        .skillsGrid {
            margin: 0 auto;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            width: 60%; 

            -webkit-animation: skillsGrid 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: skillsGrid 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @-webkit-keyframes skillsGrid {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }

              @keyframes skillsGrid {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }


            .cell {
                height: 100%;
            }

            .linkCell {
              height: 100%;

                a {
                  &:hover {
                    color: rgb(155, 155, 155);
                  }
                }
            }
        }
    }

    .name {
        margin-top: $base-spacing * 4;
        padding-bottom: $base-spacing;

        -webkit-animation: name 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: name 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

        @-webkit-keyframes name {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

          @keyframes name {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
    }

    span {
        font-weight: 900;
    }

    .job {
        -webkit-animation: skillsGrid 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: skillsGrid 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

        @-webkit-keyframes skillsGrid {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

          @keyframes skillsGrid {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

    }

    .facePic {
        display: flex;
        flex-direction: row;
        justify-content: center;

        -webkit-animation: facePic 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: facePic 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

        @-webkit-keyframes facePic {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
          
          @keyframes facePic {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
    }

    img {
        border-radius: 100%;
        border: 3px solid $secondary-color;
        width: 40vw;
        height: 40vw;
        margin-top: $base-spacing * 8;
    }    
}

@media only screen and (min-width: 426px) {

    .profileBar {
        position: fixed;
        width: 25vw;
        min-width: 180px;
        height: 100%;

        img {
            width: 50%;
            height: 50%
        }

        .facePic {
            padding-top: 20vh;
        }
    }
}

@media only screen and (min-width: 768px) {

    .profileBar {
        width: 25vw;
        height: 100%;

        img {
            width: 50%;
            height: 50%; 
        }

        .facePic {
            padding-top: 20vh;
        }
    }
}