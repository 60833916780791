@import "./variables.scss";

.app {
    margin: 0;
    padding: 0;
    height: 100%;
    box-sizing: border-box;
    background-color: $tertiary-color;
    color: $secondary-color;
    display: flex;
    flex-direction: column;
    font-family: 'Titillium Web', sans-serif;
    
    h1, h2, h3, h4, h5, h6 {
    font-family: 'Raleway', sans-serif;
    }

    p {
        text-align: justify;
    }

    a {
        color: $secondary-color;
    }
}

@media only screen and (min-width: 426px) {
    .app {
        flex-direction: row;
        height: 100%;

        .mainContent {
            width: 100%;
            margin-left: 180px;
        }
    }
  }

  @media only screen and (min-width: 730px) {
      .app{
          .mainContent {
              margin-left: 25vw;
          }
      }
  }